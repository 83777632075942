.AppHeader {
  background-color: #9cbafc;
  display: grid;
  grid-template-columns: 40% 40% 20%;  
  font-size: calc(8px + 2vmin);
  color: rgb(0, 0, 0);
}
.AdminHeader {
  background-color: #ff0000;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4,auto);  
  grid-column-gap: 3%;
  align-items: center;
  justify-items: center;
}
.adminSearchForm{
  display: flex;
  justify-content: center;
}
.adminSearchBar{
  font-size: 30px;
  height: 50px;
  width: 250px;
}
.adminSearchButton{
  height: 50px;
  font-size: 30px;
}
.logo {
  margin-top: 5px; 
}
.searchForm {
  margin-top: 0%;
  display: flex;
  justify-content: left;
  align-items: baseline;
}
.basket {
  display: flex;
  justify-content: right;
  margin-top: 5px;
  margin-right: 5px;
}
.capturePictureButton {
  padding: 15px 25px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #3700ff;
  border: none;
  border-radius: 15px;
  font-size: 32px;
}
.addToPicDBButton{
  padding: 15px 25px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4aa825;
  border: none;
  border-radius: 15px;
  font-size: 32px;
}
.cameraButtonDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cameraVideo {
  display: flex;
  justify-content: center;
}
/* BLINKING SIGN */
.blink{
  font-size:100px; 
  color: red;
  width:420px;
  height:150px;
  border:1px solid black;
  animation: blink 5s infinite;
  margin: 0;
}
@keyframes blink{
  0% {
    background: red;
  }
  20% {
    background: green;
  }
  40% {
    background: yellow;
  }
  60% {
    background: blue;
  }
  80% {
    background: orange;
  }
  100% {
    background: red;
  }
}
@-webkit-keyframes blink{
  0% {
    background: red;
  }
  20% {
    background: green;
  }
  40% {
    background: yellow;
  }
  60% {
    background: blue;
  }
  80% {
    background: orange;
  }
  100% {
    background: red;
  }
}
/* BLINKING SIGN END*/